<template>
  <div class="personage m-b-20 flex">
    <div class="personage_main flexs">
      <div class="personage_head m-r-20">
        <img :src="userInfo.image" alt="" />
      </div>
      <div class="personage_con">
        <div class="personage_top flexs">
          <div class="personage_name m-r-20">{{ userInfo.name }}</div>
          <div class="personage_table m-r-20">
            {{ getPosition(userInfo.position) }}
          </div>
          <div class="personage_star" v-if="userInfo.position == 4">
            <el-rate disabled v-model="userInfo.level"></el-rate>
          </div>
        </div>
        <div class="person-tip">
          <span class="m-r-10">{{ userInfo.text }}</span>
          <img class="icon" src="../assets/image/personage/icon35.png" />
        </div>
        <ul class="personage_list flexs m-t-20">
          <li>
            <img src="../assets/image/personage/icon.png" alt="" />
            <span>{{ userInfo.id }}</span>
          </li>
          <li>
            <img src="../assets/image/personage/icon32.png" alt="" />
            <span>{{ userInfo.mobile }}</span>
          </li>
          <li>
            <img src="../assets/image/personage/icon33.png" alt="" />
            <span>{{ userInfo.dian_name }}</span>
          </li>
        </ul>
        <div class="person-top-bot m-t-20" v-if="userInfo.position != 5">
          <div class="person-progress">
            <div class="person-s" :style="{width: getWidth()}"></div>
          </div>
          <div class="bot-flex">
            <span>经纪人</span>
            <span>经理</span>
            <span>店长</span>
            <span>区域总监</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <img @click="$refs.profile.open()" class="personage_code" src="../assets/image/personage/icon34.png" />
      <img @click="refresh" class="refresh" src="../assets/image/personage/refresh.png" alt="">
    </div>
    <!-- 个人资料弹框 S -->
    <profile-pop ref="profile"></profile-pop>
    <!-- 个人资料弹框 E -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfilePop from "./ProfilePop";
export default {
  components: { ProfilePop },
  computed: {
    ...mapGetters(["userInfo", "token", "city"]),
  },
  methods: {
    //刷新
    refresh () {
      this.$axios.BangLookFangRefresh().then(res => {
        this.getUserInfo()
        this.$message({ message: '刷新成功', type: 'success' });
      })
    },
    getWidth () {
      let width = 0
      switch (this.userInfo.position) {
        case 1:
          width = '100%'
          break;
        case 2:
          width = '75%'
          break;
        case 3:
          width = '50%'
          break;
        case 4:
          width = '25%'
          break;
        case 5:

          break;
        default:
          break;
      }
      return width
    }
  },
};
</script>

<style lang="less" scoped>
.person-top-bot {
  margin-top: 20px;
  .bot-flex {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
  }
  .person-progress {
    width: 480px;
    height: 10px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
    position: relative;
  }
  .person-s {
    display: block;
    position: absolute;
    left: 0px;
    height: 10px;
    width: 100%;
    background: #d6a364;
    background: linear-gradient(90deg, #f8d9ad, #d6a364);
    top: 0px;
  }
}
.person-tip {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin: 15px 0px;
  .icon {
    width: 18px;
    height: 18px;
  }
}
.personage {
  padding: 25px 40px;
  background: #3e7aec;
  .personage_main {
    align-items: flex-start;
  }
  .personage_code {
    width: 44px;
    height: 44px;
    cursor: pointer;
  }
  .refresh {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    margin-top: 40px;
    cursor: pointer;
  }
  .personage_head {
    img {
      width: 88px;
      height: 88px;
      border-radius: 50%;
    }
  }
}
.personage_name {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}
.personage_table {
  height: 28px;
  color: #ffffff;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  padding: 0 10px;
  background: rgba(91, 164, 238, 0.4);
  border: 1px solid #ffffff;
  border-radius: 4px;
}
.personage_list {
  li {
    display: flex;
    margin-right: 40px;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      color: #ffffff;
      font-size: 16px;
    }
  }
}
</style>
