// 客源
<template>
  <div class="customer">
    <person-head></person-head>
    <div class="customer_box b-c-f">
      <ul class="customer_ul">
        <li
          v-for="(item, index) in list"
          :key="index"
          :class="{ active: index % 2 == 0 }"
        >
          <div class="customer_ul_list flex">
            <div class="customer_ul_li flexs">
              <span>跟进时间：</span>
              <span>{{ item.createtime }}</span>
            </div>
            <div class="customer_ul_li flexs">
              <span>跟进客户：</span>
              <span>{{ item.rec_name }}</span>
            </div>
            <div class="customer_ul_li flexs">
              <span>联系方式：</span>
              <span>{{ item.rec_mobile }}</span>
            </div>
          </div>
          <div class="customer_ul_list flex m-t-15">
            <div class="customer_ul_li flexs">
              <span>跟进内容：</span>
              <span>{{ item.remark }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="paging" v-if="list.length">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import PersonHead from "../../components/PersonHead";
export default {
  components: { PersonHead },
  data() {
    return {
      page: 1, //分页
      total: 0, //总页数
      list: [], //列表
    };
  },
  methods: {
    changePage (e) {
      this.page = e
      this.getclientsGenLookLog()
    },
    getclientsGenLookLog() {
      this.$axios
        .clientsGenLookLog({
          page: this.page,
        })
        .then((res) => {
          this.list = res.data.data;
          this.total = res.data.total;
        });
    },
  },
  created() {
    this.getclientsGenLookLog();
  },
};
</script>

<style lang="less" scoped>
.customer_ul {
  li {
    height: 110px;
    display: flex;
    padding: 30px 50px;
    flex-direction: column;
    .customer_ul_list {
      flex-wrap: wrap;
      flex: 1;
      .customer_ul_li {
        span {
          font-size: 16px;
          color: #999999;
          &:last-child {
            color: #333333;
          }
        }
      }
    }
  }
  .active {
    background: #f7fcff;
  }
}
</style>
